@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Maitree:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

:root {
    --bg-color: #ffffff;
    --text-color: #2C3E50;
    --accent-color: #3498DB;
    --secondary-accent: #2980B9; 
    --tertiary-accent: #85C1E9;
    --question-color: #34495E;
    --footer-bg: #0b0d0e;
    --footer-text: #ECF0F1;
    --typing-speed: 3s;
    --page-transition: 0.6s;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

body {
    position: relative;
    min-height: 100vh;
    margin: 0;
    padding-bottom: 150px; /* Hauteur du footer */
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
    background-color: var(--bg-color);
    background-image: 
        linear-gradient(rgba(52, 152, 219, 0.03) 1px, transparent 1px),
        linear-gradient(90deg, rgba(52, 152, 219, 0.03) 1px, transparent 1px);
    background-size: 20px 20px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
}

html, #root {
    height: 100%;
    margin: 0;
    padding: 0;
}

main {
    flex: 1 0 auto;
    position: relative;
    overflow: hidden;
}

/* .page-transition-enter {
    opacity: 0;
    transform: translateX(100%);
}

.page-transition-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity var(--page-transition) ease-in-out, transform var(--page-transition) ease-in-out;
}

.page-transition-exit {
    opacity: 1;
    transform: translateX(0);
}

.page-transition-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity var(--page-transition) ease-in-out, transform var(--page-transition) ease-in-out;
}  */

.progress-container {
    width: 90%;
    max-width: 600px;
    height: clamp(4px, 1.5vw, 6px);
    background-color: #e5e7eb;
    border-radius: 999px;
    margin: clamp(12px, 3vw, 20px) auto;
    position: relative;
    overflow: hidden;
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
}

.progress-bar {
    height: 100%;
    background: linear-gradient(to right, var(--accent-color), var(--secondary-accent));
    border-radius: 999px;
    transition: width 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    animation: progressPulse 1.5s ease-in-out infinite;
}

@keyframes progressPulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
    }
}

.progress-text {
    position: absolute;
    top: clamp(-20px, -4vw, -25px);
    right: 0;
    font-size: clamp(0.875rem, 1.8vw, 1rem);
    color: var(--text-color);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 500;
}

.question-count {
    text-align: center;
    margin-top: clamp(8px, 2vw, 12px);
    font-size: clamp(0.875rem, 1.8vw, 1rem);
    color: var(--text-color);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 500;
}

p {
    display: flex;
    flex-direction: column;
    color: var(--text-color);
    line-height: 1.6;
    min-height: 0;
    font-size: clamp(0.875rem, 1.8vw, 1rem);
}

.content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: clamp(1.5rem, 4vw, 3rem);
    text-align: center;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}

.logo {
    width: clamp(120px, 25vw, 280px);
    height: auto;
    margin-bottom: clamp(1rem, 3vw, 1.5rem);
    filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
}

.content.fade-in {
    opacity: 1;
    transform: translateY(0);
}

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--bg-color);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
    transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.preloader.fade-out {
    opacity: 0;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.loader .inner {
    width: clamp(8px, 2vw, 12px);
    height: clamp(8px, 2vw, 12px);
    border-radius: 50%;
    background-color: var(--accent-color);
    animation: loader 0.6s cubic-bezier(0.4, 0, 0.2, 1) infinite alternate;
}

.loader .inner.one {
    animation-delay: -0.2s;
}

.loader .inner.two {
    animation-delay: -0.1s;
}

@keyframes loader {
    from {
        transform: scale(1);
        opacity: 1;
    }
    to {
        transform: scale(0.5);
        opacity: 0.5;
    }
}

.page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 150px);
    padding: clamp(1.5rem, 4vw, 3rem);
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.page.active {
    opacity: 1;
    transform: translateY(0);
}
a {
    text-decoration: none;
    color: var(--accent-color);
    transition: color 0.3s ease;
}

a:hover {
    color: var(--secondary-accent);
}
h1 {
    background: linear-gradient(135deg, var(--accent-color), var(--secondary-accent));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 800;
    font-size: clamp(1.75rem, 4vw, 3rem);
    line-height: 1.2;
    margin-bottom: clamp(1rem, 3vw, 1.5rem);
    max-width: 90%;
    word-wrap: break-word;
    letter-spacing: -0.02em;
    padding: 0 1rem;
}

#title {
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0.5rem;
    animation: typing var(--typing-speed) steps(40, end);
}

h2 {
    color: var(--question-color);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: clamp(1rem, 2.5vw, 1.25rem);
    font-weight: 500;
    margin-top: clamp(1rem, 4vw, 1rem);
    margin-bottom: clamp(1rem, 4vw, 1rem);
    max-width: 90%;
    word-wrap: break-word;
    text-wrap: balance;
    line-height: 1.6;
    padding: 0 0rem;
}

.legalt {
    color: var(--accent-color);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 700;
    font-size: clamp(1.5rem, 3.5vw, 2.5rem);
    margin: clamp(2rem, 5vw, 3rem) auto;
    max-width: 800px;
    word-wrap: break-word;
    text-wrap: balance;
    letter-spacing: -0.01em;
    padding: 0 1rem;
    text-align: center;
    border-bottom: 2px solid var(--accent-color);
    padding-bottom: 0.5rem;
    width: fit-content;
}

h4 {
    font-family: "Mulish", sans-serif;
    font-size: clamp(0.875rem, 1.8vw, 1rem);
    font-weight: 400;
    justify-content: center;
    margin-top: clamp(2rem, 5vw, 3rem);
    margin-bottom: clamp(-1.5rem, -4vw, -2rem);
    color: var(--text-color);
    text-wrap: balance;
    opacity: 0.9;
    padding: 0 1rem;
}

h5 {
    color: var(--question-color);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: clamp(1.125rem, 2.4vw, 1.25rem);
    font-weight: 600;
    margin: clamp(1.25rem, 3.5vw, 1rem) 0;
    max-width: 95%;
    word-wrap: break-word;
    line-height: 1.6;
    padding: 0 0rem;
    letter-spacing: -0.01em;
    text-wrap: balance;
    transition: color 0.3s ease;
}

h5:hover {
    /* color: var(--accent-color); */
}
h6 {
    color: var(--bg-color);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: clamp(0.70rem, 1.8vw, 0.65rem);
    font-weight: 400;
    justify-content: center;
    margin-bottom: clamp(0rem, -4vw, -2rem);
    text-wrap: balance;
    opacity: 0.9;
    margin: 0rem;
    margin-top: 1.5rem;
    padding: 0 1rem;
}

.btn, .restart-btn {
    background: linear-gradient(135deg, var(--accent-color), var(--secondary-accent));
    color: #ffffff;
    border: none;
    padding: clamp(0.875rem, 2vw, 1.3rem) clamp(1.5rem, 4vw, 3rem);
    border-radius: clamp(100px, 1.5vw, 100px);
    font-family: "Mulish", sans-serif;
    font-size: clamp(0.875rem, calc(100% + 0.2vw), 1.3rem);
    font-weight: 500;
    text-decoration: none;
    display: inline-block;
    position: relative;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    overflow: hidden;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    margin: clamp(8px, 2vw, 12px) 0;
    max-width: 85vw;
    white-space: normal;
    text-align: center;
    min-width: fit-content;
    transform: scale(1);
}
.btn{
    margin-top: 1.5rem;
}
.btn_answer{
    background: linear-gradient(135deg, var(--accent-color), var(--secondary-accent));
    color: #ffffff;
    border: none;
    padding: clamp(0.875rem, 2vw, 1.3rem) clamp(1.5rem, 4vw, 3rem);
    border-radius: clamp(100px, 1.5vw, 100px);
    font-family: "Mulish", sans-serif;
    font-size: clamp(0.875rem, calc(100% + 0.2vw), 1.3rem);
    font-weight: 500;
    text-decoration: none;
    display: inline-block;
    position: relative;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    overflow: hidden;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    margin: clamp(8px, 2vw, 12px) 0;
    max-width: 85vw;
    white-space: normal;
    text-align: center;
    min-width: fit-content;
    transform: scale(1);
}

.btn_answer::before{
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.2),
        transparent
    );
    transition: 0.5s;
}

.btn_answer:hover::before {
    left: 100%;
}

.btn_answer:hover{
    transform: translateY(-3px) scale(1.02);
    box-shadow: 0 15px 25px -5px rgba(0, 0, 0, 0.15), 0 10px 10px -5px rgba(0, 0, 0, 0.08);
    background: linear-gradient(150deg, var(--accent-color), var(--secondary-accent));
}

.btn_answer:active{
    transform: translateY(2px) scale(0.98);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
    transition: all 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.btn-animated {
    animation: moveInBottom 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
}

@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(30px) scale(0.95);
    }
    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

.restart-btn {
    margin-top: clamp(1.5rem, 4vw, 2.5rem);
}

.contactlist {
    text-align: center;
    padding: 0 clamp(1rem, 5vw, 2rem);
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
}

.contactpage {
    text-align: center;
    padding: 0 clamp(1rem, 5vw, 2rem);
    max-width: 800px;
    margin: 0 auto;
}

.contactpage p {
    font-size: clamp(1rem, 2vw, 0.95rem);
    line-height: 1.8;
    margin: 1.5rem 0;
    text-wrap: balance;
    color: var(--text-color);
}

.contactpage a {
    color: var(--accent-color);
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
}

.contactpage a:hover {
    color: var(--secondary-accent);
}

.btn::before, .restart-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.2),
        transparent
    );
    transition: 0.5s;
}

.btn:hover::before, .restart-btn:hover::before {
    left: 100%;
}

.btn:hover, .restart-btn:hover {
    transform: translateY(-3px) scale(1.02);
    box-shadow: 0 15px 25px -5px rgba(0, 0, 0, 0.15), 0 10px 10px -5px rgba(0, 0, 0, 0.08);
    background: linear-gradient(150deg, var(--accent-color), var(--secondary-accent));
}

.btn:active, .restart-btn:active {
    transform: translateY(2px) scale(0.98);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
    transition: all 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.btn-animated {
    animation: moveInBottom 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
}

@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(30px) scale(0.95);
    }
    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

@keyframes rotateIcon {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

.restart-btn {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin: 2rem auto;
    padding: clamp(0.75rem, 2vw, 1rem) clamp(1.5rem, 4vw, 2rem);
    font-size: clamp(0.875rem, calc(100% + 0.2vw), 1rem);
    font-weight: 500;
    color: #fff;
    background: linear-gradient(135deg, var(--accent-color), var(--secondary-accent));
    border: none;
    border-radius: clamp(6px, 1.5vw, 8px);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.restart-btn svg {
    width: clamp(20px, 1.5vw, 24px);
    height: clamp(20px, 1.5vw, 24px);
}

.restart-btn:active svg {
    animation: rotateIcon 0.6s ease;
}

.more-ideas-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 2rem;
    padding: clamp(0.75rem, 2vw, 1rem) clamp(3.5rem, 4vw, 3.5rem);
    font-size: clamp(0.875rem, calc(100% + 0.2vw), 1rem);
    font-weight: 500;
    color: var(--accent-color);
    background: transparent;
    border: 2px solid var(--accent-color);
    border-radius: clamp(6px, 1.5vw, 8px);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.more-ideas-btn:hover {
    background-color: rgba(52, 152, 219, 0.1);
    transform: translateY(-2px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.more-ideas-btn:active {
    transform: translateY(0);
}

#options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: clamp(0.75rem, 2vw, 1rem);
    margin-top: clamp(0.75rem, 2vw, 1rem);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    padding: 0 1rem;
}

#question, #options {
    justify-content: center;
    font-size: clamp(1.7rem, 2.5vw, 1.25rem);
    transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    text-wrap: balance;
}
#question{
   margin-bottom: 2rem;
}
.analysis-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: var(--bg-color);
    background-image: 
        linear-gradient(rgba(52, 152, 219, 0.03) 1px, transparent 1px),
        linear-gradient(90deg, rgba(52, 152, 219, 0.03) 1px, transparent 1px);
    background-size: 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
}

.analysis-content {
    background: none;
    padding: clamp(1.5rem, 3vw, 2rem);
    border-radius: clamp(12px, 2vw, 8px);
    /* box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1); */
    width: min(90%, 500px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    text-align: center;
    margin: auto;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.analysis-spinner {
    width: clamp(40px, 8vw, 60px);
    height: clamp(40px, 8vw, 60px);
    border: 3px solid var(--accent-color);
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin 1.2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    margin: 0 auto;
}

.analysis-text {
    font-family: "Plus Jakarta Sans", sans-serif;
    text-align: center;
    width: 100%;
    animation: textPulse 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes textPulse {
    0% {
        opacity: 0.9;
        transform: scale(1);
    }
    50% {
        opacity: 1;
        transform: scale(1.1);
    }
    100% {
        opacity: 0.9;
        transform: scale(1);
    }
}

.analysis-text h3 {
    color: var(--text-color);
    font-size: clamp(1.25rem, 2.5vw, 1.5rem);
    margin-bottom: 1.5rem;
    font-weight: 600;
    font-family: "Plus Jakarta Sans", sans-serif;
    text-wrap: balance;
    text-wrap: pretty;
    /* transition: all 2s cubic-bezier(0.4, 0, 0.2, 1); */
}

.analysis-progress {
    background: #f3f4f6;
    height: 12px;
    border-radius: 999px;
    overflow: hidden;
    margin: 1rem auto;
    width: 100%;
    max-width: 300px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.analysis-progress-bar {
    height: 100%;
    background: linear-gradient(135deg, var(--accent-color), var(--secondary-accent));
    border-radius: 999px;
    transition: width 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.analysis-percentage {
    font-size: clamp(1rem, 2vw, 1.25rem);
    color: var(--accent-color);
    font-weight: 600;
    margin-top: 0.5rem;
    font-family: "Plus Jakarta Sans", sans-serif;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.job-card {
    background-color: #ffffff;
    border: 1px solid #e5e7eb;
    padding: clamp(1.25rem, 3.5vw, 2rem);
    margin-bottom: clamp(1rem, 3vw, 1.5rem);
    border-radius: clamp(8px, 2vw, 12px);
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    text-align: left;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    opacity: 0;
    transform: translateY(30px);
    text-wrap: balance;
    text-wrap: pretty;
    white-space: nowrap;
    animation: cardAppear 0.8s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
    animation-delay: calc(0.15s * var(--card-index, 0));
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    width: 100%;
}

@keyframes cardAppear {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.job-card:hover {
    border-color: var(--accent-color);
    transform: translateY(-4px) scale(1.02);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.jobrtitle {
    font-size: clamp(1.25rem, 2.5vw, 1.5rem);
    margin-bottom: clamp(2rem, 5vw, 3rem);
    color: var(--text-color);
    font-weight: 600;
    text-wrap: balance;
    text-wrap: pretty;
    white-space: nowrap;
}

@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

.job-card h3 {
    color: var(--accent-color);
    margin-top: clamp(0.5rem, 1.5vw, 0.75rem);
    margin-bottom: clamp(1.5rem, 4vw, 2rem);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 700;
    font-size: clamp(1.125rem, 2.5vw, 1.5rem);
    line-height: 1.4;
    overflow: hidden;
    text-wrap: balance;
    text-wrap: pretty;
    white-space: nowrap;
    animation: typing var(--typing-speed) steps(40, end);
}

.job-explanation {
    margin-top: clamp(0.75rem, 2vw, 1rem);
    font-size: clamp(0.875rem, 1.8vw, 1rem);
    color: var(--text-color);
    line-height: 1.6;
}

#job-results {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(500px, 100%), 1fr));
    gap: clamp(1rem, 2.5vw, 2rem);
    width: 100%;
    padding: 0 1rem;
    margin-bottom: 2rem;
    text-wrap: balance;
    text-wrap: pretty;
}

.job-detail {
    text-align: left;
    font-weight: 400;
    padding-right: 0;
    margin-top: clamp(0.75rem, 2vw, 1rem);
    color: var(--text-color);
    opacity: 0.9;
    font-size: clamp(0.875rem, 1.8vw, 1rem);
    text-wrap: balance;
    text-wrap: pretty;
}

blockquote {
    font-style: italic;
    border-left: 4px solid var(--accent-color);
    padding: clamp(0.75rem, 2vw, 1rem) 0 clamp(0.75rem, 2vw, 1rem) clamp(1rem, 3vw, 1.5rem);
    margin: clamp(1.5rem, 4vw, 2rem) 0;
    color: var(--text-color);
    text-align: left;
    background-color: rgba(52, 152, 219, 0.05);
    border-radius: 0 8px 8px 0;
    font-size: clamp(0.875rem, 1.8vw, 1rem);
}

footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(135deg, var(--footer-bg), #0b0d0e);
    color: var(--footer-text);
    padding: 0.75rem 0.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* gap: 0.5rem; */
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.15);
    height: 100px;
}

footer a {
    color: var(--footer-text);
    text-decoration: none;
    font-size: 0.95rem;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 500;
    transition: all 0.3s ease;
    position: relative;
    padding: 0rem 1rem;
    display: inline-block;
    margin: 0 0.5rem;
    opacity: 0.9;
}

footer a:hover {
    color: #fff;
    /* transform: translateY(-2px);
    opacity: 1; */
}

footer a::after {
    /* content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    background-color: #fff;
    transition: all 0.3s ease;
    transform: translateX(-50%); */
}

footer a:hover::after {
    width: 80%;
}

.legalp {
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 2rem;
    font-size: clamp(1rem, 2vw, 0.95rem);
    line-height: 1.8;
    color: var(--text-color);
    white-space: pre-line;
    display: block;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 500;
    transition: all 0.3s ease;
}

.questionnaire {
    transform: scale(0);
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform-origin: center;
    width: 100%;
    padding: 1rem 8rem;
    margin-bottom: 2rem;
    animation: explode 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

@keyframes explode {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.fade-in {
    opacity: 1;
}

.fade-out {
    opacity: 0;
}

@media (max-width: 768px) {
    h1 {
        font-size: clamp(1.5rem, 4vw, 2rem);
        padding: 0 0.75rem;
    }
    
    h2 {
        font-size: clamp(1rem, 2.2vw, 1.125rem);
        padding: 0 0.75rem;
    }
    
    .btn, .restart-btn {
        width: 100%;
        padding: clamp(0.75rem, 2vw, 0.875rem) clamp(1rem, 4vw, 1.5rem);
        font-size: clamp(0.875rem, calc(100% + 0.2vw), 1rem);
    }
    
    footer a {
        display: inline-block;
        margin: 0.25rem 0.5rem;
        font-size: clamp(0.75rem, 1.5vw, 0.875rem);
        padding: 0.25rem 0.5rem;
    }

    .content {
        padding: clamp(1rem, 3vw, 1.5rem);
    }

    #job-results {
        padding: 0 0.75rem;
    }

    .job-card {
        margin-bottom: 1rem;
    }

    .analysis-content {
        width: 95%;
        padding: 1.5rem;
    }

    .questionnaire {
        padding: 1rem;
    }

    .legalp {
        text-align: left;
        max-width: 800px;
        margin: 0 auto;
        padding: 0 2rem;
        font-size: clamp(0.8rem, 2vw, 0.8rem);
        line-height: 1.8;
        color: var(--text-color);
        white-space: pre-line;
        display: block;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 500;
        transition: all 0.3s ease;
    }
    .contactpage p {
        font-size: clamp(0.8rem, 2vw, 0.8rem);
        line-height: 1.8;
        margin: 1.5rem 0;
        text-wrap: balance;
        color: var(--text-color);
    }
    
}

@media (max-width: 480px) {
    .content {
        padding: 1rem;
    }
    
    .page {
        min-height: calc(100vh - 150px);
    }
    
    #title {
        overflow: hidden;
        white-space: wrap;
        animation: typing var(--typing-speed) steps(end, end);
        text-wrap: balance;
        text-wrap: pretty;
    }

    h1 {
        font-size: clamp(2.3rem, 3.5vw, 1.5rem);
        padding: 0 0.5rem;
        text-wrap: balance;
        text-wrap: pretty;
    }
    
    h2 {
        font-size: clamp(0.875rem, 2vw, 1rem);
        padding: 0 0.5rem;
        text-wrap: balance;
        text-wrap: pretty;
    }
    #question {
        justify-content: center;
        font-size: clamp(1.5rem, 2.5vw, 1.25rem);
        transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        text-wrap: pretty;
}
    .btn, .restart-btn {
        width: 100%;
        padding: 1.2rem 1rem;
        font-size: clamp(0.875rem, calc(100% + 0.2vw), 1.05rem);
        text-wrap: balance;
        text-wrap: pretty;
    }
    .btn {
        width: 60%;
        padding: 1.2rem 2rem;
    }

    .btn_answer {
        padding: clamp(1rem, 2vw, 1rem) clamp(3rem, 4vw, 1rem);
        font-family: "Mulish", sans-serif;
        font-size: clamp(0.875rem, calc(100% + 0.2vw), 1.1rem);
        margin: clamp(8px, 2vw, 12px) 0;
        width: 100%;
        text-align: center;
        min-width: fit-content;
    }
    .restart-btn {
        width: 85%;
        max-width: 300px;
        margin: 1rem auto;
        padding: 1.3rem 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .more-ideas-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 2rem;
        padding: 1.3rem 3.8rem;
        font-size: clamp(0.875rem, calc(100% + 0.2vw), 1rem);
        font-weight: 500;
        color: var(--accent-color);
        background: transparent;
        border: 2px solid var(--accent-color);
        border-radius: clamp(6px, 1.5vw, 8px);
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        position: relative;
        overflow: hidden;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    
    .job-card h3 {
        font-size: clamp(1.3rem, 2.5vw, 1.125rem);
        text-wrap: balance;
        text-wrap: pretty;
    }

    .jobrtitle {
        font-size: clamp(1.6rem, 2.5vw, 1.5rem);
        text-wrap: balance;
        text-wrap: pretty;
    }

    #job-results {
        grid-template-columns: repeat(auto-fit, minmax(min(500px, 100%), 1fr));
        gap: 1rem;
        padding: 0 0.5rem;
    }

    .job-explanation, .job-detail {
        font-size: 1rem;
    }
    
    .questionnaire {
        padding: 0 1rem;
    }

    .analysis-content {
        width: 100%;
        padding: 1.25rem;
        margin: 0.5rem;
    }

    .analysis-text h3 {
        font-size: 1.125rem;
    }

    .analysis-percentage {
        font-size: 1rem;
    }

    footer {
        padding: 1.5rem 1rem;
    }

    footer a {
        font-size: 0.85rem;
        margin: 0.25rem;
        padding: 0.25rem;
    }

    .legalp {
        text-align: left;
        max-width: 800px;
        margin: 0 auto;
        padding: 0 2rem;
        font-size: clamp(0.8rem, 2vw, 0.8rem);
        line-height: 1.8;
        color: var(--text-color);
        white-space: pre-line;
        display: block;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 500;
        transition: all 0.3s ease;
    }    
    .contactpage p {
        font-size: clamp(0.8rem, 2vw, 0.8rem);
        line-height: 1.8;
        margin: 1.5rem 0;
        text-wrap: balance;
        color: var(--text-color);
    }
    h6 {
        font-size: clamp(0.65rem, 1.5vw, 0.875rem);
        padding: 0 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}